import React from "react";
import { EmailIcon, PhoneIcon } from "../CircleIcon/CircleIcon";
import { Body1, Subtitle2 } from "../Typography/Typography";
import styles from "./FlytEmployeeList.module.scss";

const FlytEmployeeList = ({ flytEmployees, contactPage, isGlobal }) => {
  const createGroupName = (stillinger) => {
    stillinger = stillinger
      .filter(({ group }) => group)
      .map(
        ({ name, group }, index) =>
          `${
            stillinger.every(({ name: globalName }) => name === globalName) &&
            index > 0
              ? ""
              : name
          } ${group.kommune.name} ${group.year}`
      );
    return stillinger.join(", ");
  };
  return (
    <div className={contactPage ? styles.contactPage : ""}>
      {flytEmployees.length > 0
        ? flytEmployees.map(
            (
              { name, phone, stillinger, email, kommune, groups, score, _key },
              index
            ) => (
              <div className={styles.employee} key={`flyt-ansatt-${index}`}>
                <div>
                  <Subtitle2>{name}</Subtitle2>
                  <Body1>
                    {isGlobal
                      ? createGroupName(stillinger)
                      : stillinger.length > 0 && stillinger[0].name}
                  </Body1>
                </div>
                <div className={styles.contactIcons}>
                  <PhoneIcon phone={phone} />
                  <EmailIcon email={email} />
                </div>
              </div>
            )
          )
        : "No results"}
    </div>
  );
};

export default FlytEmployeeList;
