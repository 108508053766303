import React from "react";
import BlockContent from "../../BlockContent/BlockContent";
import { Subtitle2 } from "../../Typography/Typography";
import styles from "./ImportantMessage.module.scss";

const ImportantMessage = ({ content, oldMessage }) => {
  const { importantMessageTitle, importantMessage, showImportantMessage } =
    content || {};

  if (oldMessage) {
    return (
      <div className={styles.oldMessage}>
        <Subtitle2>
          <span className={styles.icon}>i</span>
          Denne gruppen er over 1 år gammel
        </Subtitle2>
      </div>
    );
  } else if (showImportantMessage) {
    return (
      <div className={styles.importantMessage}>
        <Subtitle2>
          <span className={styles.icon}>i</span>
          {importantMessageTitle}
        </Subtitle2>
        <BlockContent blocks={importantMessage} />
      </div>
    );
  } else {
    return null;
  }
};

export default ImportantMessage;
