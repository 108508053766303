import React from "react";
import { slugify } from "../../../lib/helpers";
import Link from "../../Link/Link";
import styles from "./YearList.module.scss";

const YearList = ({ otherYears, kommuneName }) => {
  return (
    <ul className={styles.yearList}>
      {otherYears.map((year, index) => (
        <li key={"year-list-" + index}>
          <Link to={`/${slugify(kommuneName)}/${year.replace("/", "-")}`}>
            {year}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default YearList;
