import React from "react";
import { Carousel } from "react-responsive-carousel";
import Image from "../../Image";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./carouselCSSOverride.scss";

const SwipeableImageCarousel = ({ images }) => {
  return (
    <Carousel showThumbs={false}>
      {images.map(({ image, caption }, index) => (
        <div key={`image-${index}`}>
          <Image image={image} width={1200} />
          {caption && <p>{caption}</p>}
        </div>
      ))}
    </Carousel>
  );
};

export default SwipeableImageCarousel;
