import React, { useState } from "react";
import Button from "../../components/Button/Button";
import Layout from "../../components/Layout/Layout";
import { Heading1, Subtitle1 } from "../../components/Typography/Typography";
import GroupSelector from "../../components/Group/GroupSelector/GroupSelector";
import ImportantMessage from "../../components/Group/ImportantMessage/ImportantMessage";
import BlockContent from "../../components/BlockContent/BlockContent";
import YearList from "../../components/Group/YearList/YearList";
import SwipeableImageCarousel from "../../components/Group/SwipeableImageCarousel/SwipeableImageCarousel";
import FlytEmployeeList from "../../components/FlytEmployeeList/FlytEmployeeList";
import { isPreviousYear } from "../../lib/helpers";
import SEO from "../../components/seo";

const Group = ({ location, pageContext }) => {
  const { groups, otherYears, kommuneName } = pageContext;
  const [selectedGroup, setSelectedGroup] = useState(groups[0]);

  console.log(selectedGroup);

  const changeGroup = (group) => setSelectedGroup(group);

  const GroupContent = () => (
    <>
      <SEO title={kommuneName} />
      <Heading1>
        {kommuneName}
        <span style={{ fontSize: "1rem", marginLeft: "1rem" }}>
          {" "}
          {selectedGroup.year}
        </span>
      </Heading1>
      {selectedGroup.introduction && (
        <BlockContent blocks={selectedGroup.introduction} />
      )}
      {isPreviousYear(selectedGroup.year) && <ImportantMessage oldMessage />}
      <GroupSelector
        groups={groups}
        changeGroup={changeGroup}
        selectedGroup={selectedGroup}
      />
      {selectedGroup.images && (
        <SwipeableImageCarousel images={selectedGroup.images} />
      )}
      {selectedGroup.recruitmentLink &&
        selectedGroup.recruitmentLink.showRecruitmentLink && (
          <>
            <Button
              type="secondary"
              href={selectedGroup.recruitmentLink.recruitmentLink}
            >
              {selectedGroup.recruitmentLink.linkText}
            </Button>
            <br />
            <br />
          </>
        )}
      {selectedGroup.importantMessage && (
        <ImportantMessage content={selectedGroup.importantMessage} />
      )}
      <BlockContent blocks={selectedGroup.content} />
      <br />
      {otherYears && otherYears.length > 0 && (
        <>
          <Subtitle1>Andre år</Subtitle1>
          <YearList kommuneName={kommuneName} otherYears={otherYears} />
        </>
      )}
      {selectedGroup.employees && selectedGroup.employees.length > 0 && (
        <>
          <br />
          <br />
          <Subtitle1>Kontaktpersoner</Subtitle1>
          <br />
          <FlytEmployeeList flytEmployees={selectedGroup.employees} />
        </>
      )}
    </>
  );

  return (
    <Layout location={location} standardPadding maxWidth>
      <GroupContent />
    </Layout>
  );
};

export default Group;
