import React from "react";
import { cn } from "../../../lib/helpers";
import styles from "./GroupSelector.module.scss";

const GroupSelector = ({ groups, changeGroup, selectedGroup }) => {
  return (
    <ul className={styles.groups}>
      {groups.map((group, index) => (
        <li
          key={`group-selector-item-${index}`}
          className={cn(
            styles.group,
            selectedGroup.name === group.name ? styles.selected : ""
          )}
        >
          <button onClick={() => changeGroup(group)}>{group.name}</button>
        </li>
      ))}
    </ul>
  );
};

export default GroupSelector;
