import React from "react";
import Icon from "../icon";
import styles from "./CircleIcon.module.scss";

const CircleIcon = ({ children, href }) => {
  return (
    <a href={href} className={styles.circleIcon}>
      {children}
    </a>
  );
};

export const PhoneIcon = ({ phone }) => {
  return (
    <CircleIcon href={`tel:+47${phone}`}>
      <Icon icon="phone" size={18} />
    </CircleIcon>
  );
};

export const EmailIcon = ({ email }) => {
  return (
    <CircleIcon href={`mailto:${email}`}>
      <Icon icon="email" size={24} />
    </CircleIcon>
  );
};
